import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";
const defaultState = {
  ebooks: [],
  ebook: {},
  image: "",
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "eBooks";

const EDIT_EBOOK = "EDIT_EBOOK";
const CREATE_EBOOK = "CREATE_EBOOK";
const DELETE_EBOOK = "DELETE_EBOOK";
const LOAD_EBOOKS_LIST = "LOAD_EBOOKS_LIST";
const GET_EBOOK = "GET_EBOOK";
const EBOOKS_PENDING = "EBOOKS_PENDING";
const POST_EBOOKS_LOGO = "POST_EBOOKS_LOGO";
const EBOOKS_ERROR = "EBOOKS_ERROR";
// all books
export const getEbooks = () => async (dispatch, getState) => {
  dispatch({ type: EBOOKS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_EBOOKS_LIST,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: EBOOKS_ERROR,
      error,
    });
  }
};
// get 1 book
export const getEbook = (id) => async (dispatch, getState) => {
  dispatch({ type: EBOOKS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_EBOOK,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: EBOOKS_ERROR, error });
  }
};

export const editEbook = (id, newEbookData) => async (dispatch, getState) => {
  dispatch({ type: EBOOKS_PENDING });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newEbookData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_EBOOK,
      payload: { id, newEbookData },
    });
  } catch (error) {
    dispatch({ type: EBOOKS_ERROR, error });
  }
};

export const createEbook = (newEbookData) => async (dispatch, getState) => {
  dispatch({ type: EBOOKS_PENDING });
  try {
    const res = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newEbookData,
      getConfigHeaders()
    );
    dispatch({
      type: CREATE_EBOOK,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: EBOOKS_ERROR, error });
  }
};

export const deleteEbook = (id) => async (dispatch, getState) => {
  dispatch({ type: EBOOKS_PENDING });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());
    dispatch({
      type: DELETE_EBOOK,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: EBOOKS_ERROR, error });
  }
};

// img Upload
export const uploadEbookImage =
  (folderName, cdnName, file) => async (dispatch) => {
    dispatch({
      type: EBOOKS_PENDING,
    });
    try {
      const data = await axios.post(
        `https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=${folderName}&bucket=${cdnName}`,
        file,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );
      console.log("data: ", data);
      dispatch({
        type: POST_EBOOKS_LOGO,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EBOOKS_ERROR,
        error,
      });
    }
  };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_EBOOKS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        ebooks: action.payload,
        loaded: true,
      };
    case GET_EBOOK:
      return {
        ...state,
        ebook: action.payload,
        loaded: true,
        isPending: false,
      };
    case EBOOKS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case EBOOKS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        ebooks: [],
        loaded: true,
      };

    case CREATE_EBOOK:
      return {
        ...state,
        isPending: false,
        error: false,
        ebooks: [...state.ebooks, action.payload],
        loaded: true,
      };

    case EDIT_EBOOK:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        ebooks: state.ebooks.map((ebook) =>
          ebook.objectId == action.payload.id
            ? action.payload.newEbookData
            : ebook
        ),
      };
    case POST_EBOOKS_LOGO:
      return {
        ...state,
        ebookLogo: action.payload,
        isPending: false,
        error: false,
        loaded: true,
      };
    case DELETE_EBOOK:
      return {
        ...state,
        isPending: false,
        error: false,
        ebooks: state.ebooks.filter(
          (ebook) => ebook.objectId != action.payload
        ),
        loaded: true,
      };
    default:
      return state;
  }
}
