import "./AddTestimonial.css";
import { FormControl, FormGroup, label, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getTestimonial,
  createTestimonial,
  editTestimonial,
  uploadTestimonialImage,
} from "../../reducers/Testimonials";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const AddTestimonial = ({
  history,
  isPending,
  testimonial,
  getTestimonial,
  createTestimonial,
  editTestimonial,
  uploadImage,
  testimonialImage,
}) => {
  const testimonialId =
    window.location.hash.includes("editTestimonial") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("testimonialId: ", testimonialId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [clientName, setClientName] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [services, setServices] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (testimonialId) {
      getTestimonial(testimonialId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [testimonialId]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        selectedImage("");
      }
    }
    if (testimonialImage) {
      console.log("testimonialImage: ", testimonialImage);
      setSelectedImageUrl(testimonialImage.cdn_url);
    }
  }, [selectedImage, testimonialImage]);

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
  };

  const submitTestimonialHandler = (e) => {
    e.preventDefault();
    const testimonialData = {
      name,
      clientName,
      country,
      Description: description,
      image: selectedImageUrl,
    };

    if (isEditingMode) {
      editTestimonial(testimonialId, testimonialData);
    } else {
      createTestimonial(testimonialData);
    }

    const formData = new FormData();
    formData.append("file", selectedImage);
    uploadImage("website", "cdn.appgain.io", formData);
    // wipeAmbassadorImageData();
    history.push("/Testimonials");
  };

  useEffect(() => {
    if (!!testimonial && testimonialId) {
      setClientName(testimonial.clientName);
      setName(testimonial.name);
      setDescription(testimonial.Description);
      setCountry(testimonial.country);
      setSelectedImageUrl(testimonial.image);
      setEdit(true);
    }
  }, [testimonial]);

  const uploadImageHandler = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    await uploadImage("website", "cdn.appgain.io", formData);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? `Edit Testimonial: ${name}` : "Add New Testimonial"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitTestimonialHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="testimonialClientName">Client Name</label>
            <FormControl
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              name="testimonialClientName"
              htmlFor="testimonialClientName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="testimonialName">Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="testimonialName"
              htmlFor="testimonialName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="testimonialCountry">Testimonial Country</label>
            <FormControl
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              name="testimonialCountry"
              htmlFor="testimonialCountry"
              placeholder="Enter Country"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="testimonialDescription">Description</label>
            <FormControl
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="testimonialDescription"
              htmlFor="testimonialDescription"
              placeholder="Enter Description"
              required
            />
          </div>
          <div className="fieldDiv">
            <label>
              {isEditingMode ? "Change" : "Upload"} Testimonial Image
            </label>
            <div className="file-input">
              {/* SVG support */}
              <input
                type="file"
                accept="image/*"
                placeholder="Choose Testimonial Image"
                onChange={storeImageHandler}
                className="file"
                id="file"
              />
              <label htmlFor="file">
                {selectedImage || isEditingMode ? "Change " : "Select "} Image
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedImage && (
                <React.Fragment>
                  <p>Please Upload the image</p>
                  <button
                    onClick={uploadImageHandler}
                    className="btn btn-success w-100 my-3"
                  >
                    Upload image
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className="btns">
          <Button
            disabled={!edit}
            className="submitBtn"
            type="submit"
            bsStyle="primary"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Testimonials.isPending,
  testimonial: state.Testimonials.testimonial,
  testimonialImage: state.Testimonials.testimonialImage,
});

const actions = (dispatch) => ({
  getTestimonial: (testimonialId) => dispatch(getTestimonial(testimonialId)),
  createTestimonial: (newTestimonialData) =>
    dispatch(createTestimonial(newTestimonialData)),
  editTestimonial: (testimonialId, newTestimonialData) =>
    dispatch(editTestimonial(testimonialId, newTestimonialData)),
  uploadImage: (folderName, cdnName, file) =>
    dispatch(uploadTestimonialImage(folderName, cdnName, file)),
});

export default connect(mapStateToProps, actions)(AddTestimonial);
