import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const defaultState = {
  integrations: [],
  integration: {},
  integrationLogo: "",
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "Integrations";

const EDIT_INTEGRATION = "EDIT_INTEGRATION";
const CREATE_INTEGRATION = "CREATE_INTEGRATION";
const DELETE_INTEGRATION = "DELETE_INTEGRATION";
const LOAD_INTEGRATIONS_LIST = "LOAD_INTEGRATIONS_LIST";
const GET_INTEGRATION = "GET_INTEGRATION";
const INTEGRATIONS_PENDING = "INTEGRATIONS_PENDING";
const POST_INTEGRATIONS_LOGO = "POST_INTEGRATIONS_LOGO";
const INTEGRATIONS_ERROR = "INTEGRATIONS_ERROR";

export const getIntegrations = () => async (dispatch, getState) => {
  dispatch({ type: INTEGRATIONS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_INTEGRATIONS_LIST,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: INTEGRATIONS_ERROR,
      error,
    });
  }
};

export const getIntegration = (id) => async (dispatch, getState) => {
  dispatch({ type: INTEGRATIONS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_INTEGRATION,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: INTEGRATIONS_ERROR, error });
  }
};

export const editIntegration =
  (id, newIntegrationData) => async (dispatch, getState) => {
    dispatch({ type: INTEGRATIONS_PENDING });
    try {
      await appBackendAppgainUrl(
        `${url}/${id}`,
        "put",
        newIntegrationData,
        getConfigHeaders()
      );
      dispatch({
        type: EDIT_INTEGRATION,
        payload: { id, newIntegrationData },
      });
    } catch (error) {
      dispatch({ type: INTEGRATIONS_ERROR, error });
    }
  };

export const createIntegration =
  (newIntegrationData) => async (dispatch, getState) => {
    dispatch({ type: INTEGRATIONS_PENDING });
    try {
      const res = await appBackendAppgainUrl(
        `${url}`,
        "post",
        newIntegrationData,
        getConfigHeaders()
      );
      dispatch({
        type: CREATE_INTEGRATION,
        payload: res,
      });
    } catch (error) {
      dispatch({ type: INTEGRATIONS_ERROR, error });
    }
  };

export const deleteIntegration = (id) => async (dispatch, getState) => {
  dispatch({ type: INTEGRATIONS_PENDING });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());
    dispatch({
      type: DELETE_INTEGRATION,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: INTEGRATIONS_ERROR, error });
  }
};

// Logo Upload
export const uploadIntegrationLogo =
  (folderName, cdnName, file) => async (dispatch) => {
    dispatch({
      type: INTEGRATIONS_PENDING,
    });
    try {
      const data = await axios.post(
        `https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=${folderName}&bucket=${cdnName}`,
        file,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );
      console.log("data: ", data);
      dispatch({
        type: POST_INTEGRATIONS_LOGO,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: INTEGRATIONS_ERROR,
        error,
      });
    }
  };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_INTEGRATIONS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        integrations: action.payload,
        loaded: true,
      };
    case GET_INTEGRATION:
      return {
        ...state,
        integration: action.payload,
        loaded: true,
        isPending: false,
      };
    case INTEGRATIONS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case INTEGRATIONS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        integrations: [],
        loaded: true,
      };

    case CREATE_INTEGRATION:
      return {
        ...state,
        isPending: false,
        error: false,
        integrations: [...state.integrations, action.payload],
        loaded: true,
      };

    case EDIT_INTEGRATION:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        integrations: state.integrations.map((integration) =>
          integration.objectId == action.payload.id
            ? action.payload.newIntegrationData
            : integration
        ),
      };
    case POST_INTEGRATIONS_LOGO:
      return {
        ...state,
        integrationLogo: action.payload,
        isPending: false,
        error: false,
        loaded: true,
      };
    case DELETE_INTEGRATION:
      return {
        ...state,
        isPending: false,
        error: false,
        integrations: state.integrations.filter(
          (integration) => integration.objectId != action.payload
        ),
        loaded: true,
      };
    default:
      return state;
  }
}
