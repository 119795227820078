import "./AddCustomer.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getCustomer,
  createCustomer,
  editCustomer,
  uploadCustomerLogo,
} from "../../reducers/Customers";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
const AddCustomer = ({
  history,
  isPending,
  customer,
  getCustomer,
  createCustomer,
  editCustomer,
  uploadLogo,
  customerLogo,
}) => {
  const customerId =
    window.location.hash.includes("editCustomer") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("customerId: ", customerId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [customerId]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (selectedLogo) {
      if (selectedLogo.size > 512000) {
        setShow(true);
        selectedLogo("");
      }
    }
    if (customerLogo) {
      console.log("customerLogo: ", customerLogo);
      setSelectedLogoUrl(customerLogo.cdn_url);
    }
  }, [selectedLogo, customerLogo]);

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
  };

  const submitCustomerHandler = (e) => {
    e.preventDefault();
    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const customerData = {
      Name: name,
      Website: website,
      Location: location,
      Description: stateToHTML(description.getCurrentContent()),
      Logo: selectedLogoUrl,
    };

    if (isEditingMode) {
      editCustomer(customerId, customerData);
    } else {
      createCustomer(customerData);
    }
    // wipeAmbassadorImageData();
    history.push("/Customers");
  };

  useEffect(() => {
    if (!!customer && customerId) {
      setName(customer.Name);

      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              customer.Description ? customer.Description : `<p>No Content</p>`
            )
          )
        )
      );
      setWebsite(customer.Website);
      setLocation(customer.Location);
      setSelectedLogoUrl(customer.Logo);
      setEdit(true);
    }
  }, [customer]);

  const uploadLogoHandler = async () => {
    const formData = new FormData();
    formData.append("file", selectedLogo);
    await uploadLogo("website", "cdn.appgain.io", formData);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? `Edit Customer: ${name}` : "Add New Customer"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitCustomerHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="customerName">Customer Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="customerName"
              htmlFor="customerName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="customerLocation">Customer Location</label>
            <FormControl
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              name="customerLocation"
              htmlFor="customerLocation"
              placeholder="Enter Location"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="Website">Customer Website</label>
            <FormControl
              type="text"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              name="customerWebsite"
              htmlFor="customerWebsite"
              placeholder="Enter Website"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="customerDescription">Description</label>
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                  0 && setIsDescriptionRequired(true)
              }
            />

            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Content field is required
              </p>
            )}
          </div>
          <div className="fieldDiv">
            <label>{isEditingMode ? "Change" : "Upload"} Customer Logo</label>
            <div className="file-input">
              {/* SVG support */}
              <input
                type="file"
                accept="image/*"
                placeholder="Choose Customer Logo"
                onChange={storeImageHandler}
                className="file"
                id="file"
              />
              <label htmlFor="file">
                {selectedLogo || isEditingMode ? "Change " : "Select "} Logo
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                <React.Fragment>
                  <p>Please Upload the logo</p>
                  <button
                    onClick={uploadLogoHandler}
                    className="btn btn-success w-100 my-3"
                  >
                    Upload logo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className="btns">
          <Button
            disabled={!edit}
            className="submitBtn"
            type="submit"
            bsStyle="primary"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Customers.isPending,
  customer: state.Customers.customer,
  customerLogo: state.Customers.customerLogo,
});

const actions = (dispatch) => ({
  getCustomer: (customerId) => dispatch(getCustomer(customerId)),
  createCustomer: (newCustomerData) =>
    dispatch(createCustomer(newCustomerData)),
  editCustomer: (customerId, newCustomerData) =>
    dispatch(editCustomer(customerId, newCustomerData)),
  uploadLogo: (folderName, cdnName, file) =>
    dispatch(uploadCustomerLogo(folderName, cdnName, file)),
});

export default connect(mapStateToProps, actions)(AddCustomer);
