import "./AddExpert.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getExpert,
  createExpert,
  editExpert,
  uploadExpertLogo,
} from "../../reducers/Experts";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
const AddExpert = ({
  history,
  isPending,
  expert,
  getExpert,
  createExpert,
  editExpert,
  uploadLogo,
  expertLogo,
}) => {
  const expertId =
    window.location.hash.includes("editExpert") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("expertId: ", expertId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [services, setServices] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (expertId) {
      getExpert(expertId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [expertId]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (selectedLogo) {
      if (selectedLogo.size > 512000) {
        setShow(true);
        selectedLogo("");
      }
    }
    if (expertLogo) {
      console.log("expertLogo: ", expertLogo);
      setSelectedLogoUrl(expertLogo.cdn_url);
    }
  }, [selectedLogo, expertLogo]);
  console.log(selectedLogoUrl);

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
  };

  const submitExpertHandler = (e) => {
    e.preventDefault();
    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const expertData = {
      Name: name,
      Services: services,
      Location: location,
      Description: stateToHTML(description.getCurrentContent()),
      Logo: selectedLogoUrl,
    };

    if (isEditingMode) {
      editExpert(expertId, expertData);
    } else {
      createExpert(expertData);
    }

    // wipeAmbassadorImageData();
    history.push("/Experts");
  };

  useEffect(() => {
    if (!!expert && expertId) {
      setName(expert.Name);

      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              expert.Description ? expert.Description : `<p>No Content</p>`
            )
          )
        )
      );
      setServices(expert.Services);
      setLocation(expert.Location);
      setSelectedLogoUrl(expert.Logo);
      setEdit(true);
    }
  }, [expert]);

  const uploadLogoHandler = async () => {
    const formData = new FormData();
    formData.append("file", selectedLogo);
    await uploadLogo("website", "cdn.appgain.io", formData);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? `Edit Expert: ${name}` : "Add New Expert"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitExpertHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="expertName">Expert Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="expertName"
              htmlFor="expertName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="expertLocation">Expert Location</label>
            <FormControl
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              name="expertLocation"
              htmlFor="expertLocation"
              placeholder="Enter Location"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="Services">Expert Services</label>
            <FormControl
              type="text"
              value={services}
              onChange={(e) => setServices(e.target.value)}
              name="expertServices"
              htmlFor="expertServices"
              placeholder="Enter Services"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="expertDescription">Description</label>
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                  0 && setIsDescriptionRequired(true)
              }
            />

            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Content field is required
              </p>
            )}
          </div>
          <div className="fieldDiv">
            <label>{isEditingMode ? "Change" : "Upload"} Expert Logo</label>
            <div className="file-input">
              {/* SVG support */}
              <input
                type="file"
                accept="image/*"
                placeholder="Choose Expert Logo"
                onChange={storeImageHandler}
                className="file"
                id="file"
              />
              <label htmlFor="file">
                {selectedLogo || isEditingMode ? "Change " : "Select "} Logo
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                <React.Fragment>
                  <p>Please Upload the logo</p>
                  <button
                    onClick={uploadLogoHandler}
                    className="btn btn-success w-100 my-3"
                  >
                    Upload logo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className="btns">
          <Button
            disabled={!edit}
            className="submitBtn"
            type="submit"
            bsStyle="primary"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Experts.isPending,
  expert: state.Experts.expert,
  expertLogo: state.Experts.expertLogo,
});

const actions = (dispatch) => ({
  getExpert: (expertId) => dispatch(getExpert(expertId)),
  createExpert: (newExpertData) => dispatch(createExpert(newExpertData)),
  editExpert: (expertId, newExpertData) =>
    dispatch(editExpert(expertId, newExpertData)),
  uploadLogo: (folderName, cdnName, file) =>
    dispatch(uploadExpertLogo(folderName, cdnName, file)),
});

export default connect(mapStateToProps, actions)(AddExpert);
