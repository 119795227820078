import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const defaultState = {
  testimonials: [],
  testimonial: {},
  testimonialImage: "",
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "Testimonials";

const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
const CREATE_TESTIMONIAL = "CREATE_TESTIMONIAL";
const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
const LOAD_TESTIMONIALS_LIST = "LOAD_TESTIMONIALS_LIST";
const GET_TESTIMONIAL = "GET_TESTIMONIAL";
const TESTIMONIALS_PENDING = "TESTIMONIALS_PENDING";
const POST_TESTIMONIALS_IMAGE = "POST_TESTIMONIALS_IMAGE";
const TESTIMONIALS_ERROR = "TESTIMONIALS_ERROR";

export const getTestimonials = () => async (dispatch, getState) => {
  dispatch({ type: TESTIMONIALS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_TESTIMONIALS_LIST,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: TESTIMONIALS_ERROR,
      error,
    });
  }
};

export const getTestimonial = (id) => async (dispatch, getState) => {
  dispatch({ type: TESTIMONIALS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_TESTIMONIAL,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: TESTIMONIALS_ERROR, error });
  }
};

export const editTestimonial =
  (id, newTestimonialData) => async (dispatch, getState) => {
    dispatch({ type: TESTIMONIALS_PENDING });
    try {
      await appBackendAppgainUrl(
        `${url}/${id}`,
        "put",
        newTestimonialData,
        getConfigHeaders()
      );
      dispatch({
        type: EDIT_TESTIMONIAL,
        payload: { id, newTestimonialData },
      });
    } catch (error) {
      dispatch({ type: TESTIMONIALS_ERROR, error });
    }
  };

export const createTestimonial =
  (newTestimonialData) => async (dispatch, getState) => {
    dispatch({ type: TESTIMONIALS_PENDING });
    try {
      const res = await appBackendAppgainUrl(
        `${url}`,
        "post",
        newTestimonialData,
        getConfigHeaders()
      );
      dispatch({
        type: CREATE_TESTIMONIAL,
        payload: res,
      });
    } catch (error) {
      dispatch({ type: TESTIMONIALS_ERROR, error });
    }
  };

export const deleteTestimonial = (id) => async (dispatch, getState) => {
  dispatch({ type: TESTIMONIALS_PENDING });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());
    dispatch({
      type: DELETE_TESTIMONIAL,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: TESTIMONIALS_ERROR, error });
  }
};

// Image Upload
export const uploadTestimonialImage =
  (folderName, cdnName, file) => async (dispatch) => {
    dispatch({
      type: TESTIMONIALS_PENDING,
    });
    try {
      const data = await axios.post(
        `https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=${folderName}&bucket=${cdnName}`,
        file,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );
      console.log("data: ", data);
      dispatch({
        type: POST_TESTIMONIALS_IMAGE,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: TESTIMONIALS_ERROR,
        error,
      });
    }
  };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_TESTIMONIALS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        testimonials: action.payload,
        loaded: true,
      };
    case GET_TESTIMONIAL:
      return {
        ...state,
        testimonial: action.payload,
        loaded: true,
        isPending: false,
      };
    case TESTIMONIALS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case TESTIMONIALS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        testimonials: [],
        loaded: true,
      };

    case CREATE_TESTIMONIAL:
      return {
        ...state,
        isPending: false,
        error: false,
        testimonials: [...state.testimonials, action.payload],
        loaded: true,
      };

    case EDIT_TESTIMONIAL:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        testimonials: state.testimonials.map((testimonial) =>
          testimonial.objectId == action.payload.id
            ? action.payload.newTestimonialData
            : testimonial
        ),
      };
    case POST_TESTIMONIALS_IMAGE:
      return {
        ...state,
        testimonialImage: action.payload,
        isPending: false,
        error: false,
        loaded: true,
      };
    case DELETE_TESTIMONIAL:
      return {
        ...state,
        isPending: false,
        error: false,
        testimonials: state.testimonials.filter(
          (testimonial) => testimonial.objectId != action.payload
        ),
        loaded: true,
      };
    default:
      return state;
  }
}
